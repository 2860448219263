import React, { useEffect, useRef, useState } from "react";
import getApplicationById from "../../hooks/getApplication";
import { FailureToast } from "../../components/mobile/toastify";
import AdmissionFormTemplate from "../../components/admission-form-template";
import axios from "axios";
import { usePDF } from "react-to-pdf";
import { useReactToPrint } from "react-to-print";

const logo = require("../../assets/images/png/logo.png");
function GetAdmissionForm() {
  const [inputValue, setInputValue] = useState(null);
  const [personalDetails, setPersonalDetails] = useState({});
  const [courseDetails, setCourseDetails] = useState({});
  const [courseList, setCourseList] = useState([]);
  const logo = require("../../assets/images/png/logo.png");

  useEffect(() => {
    // get course list
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const HandleGetAdmission = async () => {
    if (!inputValue) {
      FailureToast("Please enter input");
      return null;
    }
    console.log("valueee", inputValue);
    const ApplicationDetails = await getApplicationById(inputValue);
    const personalDetailsTemp = {
      applicantname: ApplicationDetails?.applicant_name,
      fathername: ApplicationDetails?.father_name,
      fatheroccupation: ApplicationDetails?.occupation_of_parent,
      fathercontact: ApplicationDetails?.father_contact,
      mothername: ApplicationDetails?.mother_name,
      mothercontact: ApplicationDetails?.mother_contact,
      address: ApplicationDetails?.address,
      district: ApplicationDetails?.district,
      state: ApplicationDetails?.state,
      taluk: ApplicationDetails?.taluk,
      pincode: ApplicationDetails?.pincode,
      dob: ApplicationDetails?.date_of_birth,
      adharnumber: ApplicationDetails?.adhar_number,
      gender: ApplicationDetails?.gender,
      religion: ApplicationDetails?.religion,
      bloodgroup: ApplicationDetails?.blood_group,
      maritalstatus: ApplicationDetails?.marital_status,
      nationality: ApplicationDetails?.nationality,
      mothertongue: ApplicationDetails?.mother_tongue,
      handicapped: ApplicationDetails?.physically_handicapped,
      mobile: ApplicationDetails?.applicant_contact_number,
      email: ApplicationDetails?.email,
      passportsizephoto: ApplicationDetails?.applicant_photo,
      application_no: ApplicationDetails?.application_number,
    };

    const courseDetails = {
      course: ApplicationDetails?.course,
      center: ApplicationDetails?.center,
      qualification: JSON.parse(ApplicationDetails?.qualification_and_marks),
    };
    setPersonalDetails(personalDetailsTemp);
    setCourseDetails(courseDetails);
  };
  const CourseName = (id) => {
    const filterData = courseList.filter((items) => items.value === id);
    if (filterData.length > 0) return filterData[0]["label"];
  };
  const contentRef = useRef(null);

  const { toPDF, targetRef } = usePDF({
    filename: `${inputValue}-admission-form.pdf`,
  });
  const handlePrint = useReactToPrint({ contentRef });

  return (
    <div>
      <div className="flex justify-center my-3">
        <div className="container flex items-center">
          <div className="w-1/5 flex justify-end">
            <img src={logo} alt="" className="h-[15vh]" />
          </div>
          <div className="w-4/5 text-center">
            <p className="text-3xl font-medium text-[#2B3690]">
              Tamilnadu Technical Training School and Paramedical Institute
            </p>
            <p className="font-medium text-lg">Run by</p>
            <p className="font-medium text-lg">
              Tamilnadu Santhome Educational & Charitable Trust
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center flex-row">
        <input
          type="text"
          className="form-control border h-11 w-[600px] px-3 bg-gray-100"
          placeholder="Search Application Number..."
          onInput={(event) => setInputValue(event.target.value)}
        />
        <button
          onClick={() => HandleGetAdmission()}
          className="px-5 h-11 border bg-blue-500 text-white font-medium"
        >
          Get Form
        </button>
      </div>
      {courseDetails && Object.keys(courseDetails)?.length > 0 && (
        <div className="flex gap-x-4 items-center justify-center mt-4">
          <button
            className="px-5 py-2 text-white bg-blue-500 border text-center"
            onClick={() => toPDF()}
          >
            Download PDF
          </button>
          <button
            className="px-5 py-2 text-white bg-orange-500 border text-center"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
      )}
      <div className="mt-10 flex justify-center">
        <div
          className=""
          ref={targetRef}
          style={{
            width: "950px", // Match A4 width
            boxSizing: "border-box",
            backgroundColor: "#fff",
          }}
        >
          <div ref={contentRef}>
            <AdmissionFormTemplate
              courseDetails={courseDetails}
              personalDetails={personalDetails}
              CourseName={CourseName}
              logo={logo}
              appno={personalDetails?.application_no}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetAdmissionForm;
