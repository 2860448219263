import axios, { all } from "axios";
import { generateAppNumber } from "./generateAppNumber";

const createNewApplication = async (allData) => {
  const ApplicationNumber = generateAppNumber();
  allData.append("application_number", ApplicationNumber);
  allData.append("application_date", new Date());
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}create_new_application.php`,
      allData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      if (res.data.success) {
        return ApplicationNumber;
      }
    })
    .catch((err) => {
      console.log("error on axios", err);
    });
};

export default createNewApplication;
