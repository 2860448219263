export const formatDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

export function getTenYearsBeforeDate() {
  const today = new Date(); // Get today's date
  const tenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 10)); // Subtract 10 years
  return tenYearsAgo.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
}
