import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FailureToast } from "../../components/mobile/toastify";
import { usePDF } from "react-to-pdf";
import AdmissionFormTemplate from "../../components/admission-form-template";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";

function DesktopThankyouPage() {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({ contentRef });
  const course = localStorage.getItem("course-details-info");
  const navigate = useNavigate();
  const personal = localStorage.getItem("personal-details-info");
  const logo = require("../../assets/images/png/logo.png");
  const [appno, setAppno] = useState(null);
  const [courseDetails, setCourseDetails] = useState({});
  const [personalDetails, setPersonalDetails] = useState({});
  const [courseList, setCourseList] = useState([]);
  const { toPDF, targetRef } = usePDF({
    filename: `${appno}-admission-form.pdf`,
  });
  useEffect(() => {
    const appno = localStorage.getItem("APPNO");
    setAppno(appno);
    localStorage.removeItem("firstStepSubmitted");
    localStorage.removeItem("secondStepSubmitted");
    localStorage.removeItem("thirdStepSubmitted");
    setCourseDetails(JSON.parse(course));
    setPersonalDetails(JSON.parse(personal));

    // get course list
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const HandleReset = () => {
    localStorage.clear();
    navigate("/");
  };
  const CourseName = (id) => {
    const filterData = courseList.filter((items) => items.value === id);
    if (filterData.length > 0) return filterData[0]["label"];
  };

  return (
    <div>
      <div className="min-h-[90vh] flex flex-col items-center justify-center">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-green-700 text-[80px] my-3"
        />
        <p className="text-secondary text-2xl font-medium italic">
          Thank you for choosing our institution to begin your journey toward
          excellence and success.
        </p>
        <p className="text-black text-xl mt-5">Your Application Number is</p>
        <p className="text-black font-medium text-4xl mt-2">{appno}</p>
        <div className="flex gap-x-4 items-center justify-center mt-4">
          <button
            className="px-5 py-2 text-white bg-blue-500 border text-center"
            onClick={() => toPDF()}
          >
            Download PDF
          </button>
          <button
            className="px-5 py-2 text-white bg-orange-500 border text-center"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <button
          onClick={() => HandleReset()}
          className="bg-primary px-5 py-3 text-white mt-4 font-medium text-lg rounded-lg"
        >
          New admission
        </button>
      </div>
      {/* <div style={{ visibility: "hidden" }}> */}
      <div className="mt-10 flex justify-center">
        <div
          className=""
          ref={targetRef}
          style={{
            width: "950px", // Match A4 width
            boxSizing: "border-box",
            backgroundColor: "#fff",
          }}
        >
          <div ref={contentRef}>
            <AdmissionFormTemplate
              CourseName={CourseName}
              courseDetails={courseDetails}
              personalDetails={personalDetails}
              logo={logo}
              appno={appno}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopThankyouPage;
