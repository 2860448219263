import React, { useEffect } from "react";
import MobileAccordion from "../../components/mobile/accordion";
import Select from "react-select";
import MobileFileUpload from "../../components/mobile/fileUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  setFirstFormStatus,
  setSecondFormStatus,
  setThirdFormStatus,
} from "../../redux/formStatusStore";
import { SuccessToast } from "../../components/mobile/toastify";
import BloodGroup from "../../json/bloodgroupList.json";
import { getTenYearsBeforeDate } from "../../components/get-format-date";

function MobilePersonalInfoForm() {
  const { firstStepSubmitted, secondStepSubmitted } = useSelector(
    (state) => state.formStatusStore
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("secondStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      dispatch(setSecondFormStatus(true));
    }
  }, []);
  const loadInitialValues = () => {
    const savedValues = localStorage.getItem("personal-details-info");
    return savedValues
      ? JSON.parse(savedValues)
      : {
          applicantname: "",
          passportsizephoto: "",
          fathername: "",
          fatheroccupation: "",
          fathercontact: "",
          mothername: "",
          mothercontact: "",
          address: "",
          district: "",
          state: "",
          taluk: "",
          pincode: "",
          dob: "",
          adharnumber: "",
          gender: "",
          religion: "",
          bloodgroup: "",
          maritalstatus: "",
          nationality: "",
          mothertongue: "",
          handicapped: "",
          mobile: "",
          email: "",
          groupphoto: "",
        };
  };
  const personalForm = useFormik({
    initialValues: loadInitialValues(),
    validationSchema: Yup.object({
      applicantname: Yup.string().required("This field is required."),
      passportsizephoto: Yup.string().required("This field is required."),
      fathername: Yup.string().required("This field is required."),
      fatheroccupation: Yup.string().required("This field is required."),
      fathercontact: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      mothername: Yup.string().required("This field is required."),
      mothercontact: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      address: Yup.string().required("This field is required."),
      district: Yup.string().required("This field is required."),
      state: Yup.string().required("This field is required."),
      taluk: Yup.string().required("This field is required."),
      pincode: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      dob: Yup.string().required("This field is required."),
      adharnumber: Yup.string()
        .matches(/^\d+$/, "Must be only digits")
        .min(12, "Number must be at least 12 digits")
        .required("This field is required"),
      gender: Yup.string().required("This field is required."),
      religion: Yup.string().required("This field is required."),
      bloodgroup: Yup.string().required("This field is required."),
      maritalstatus: Yup.string().required("This field is required."),
      nationality: Yup.string().required("This field is required."),
      mothertongue: Yup.string().required("This field is required."),
      handicapped: Yup.string().required("This field is required."),
      groupphoto: Yup.string().required("This field is required."),
      mobile: Yup.number()
        .typeError("Must be a number")
        .required("This field is required."),
      email: Yup.string()
        .email("Invalid email format")
        .required("This field is required."),
    }),
    onSubmit: (values) => {
      dispatch(setSecondFormStatus(true));
      localStorage.setItem("secondStepSubmitted", true);
      localStorage.setItem("personal-details-info", JSON.stringify(values));
      SuccessToast("Data Saved! ");
    },
  });
  const InnerContent = () => {
    return (
      <div className="py-2">
        <div className="mb-4">
          <div className="my-2">
            <label className="font-medium">
              Name of the Applicant (as entered in Hr. Sec. Certificates){" "}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="applicantname"
              value={personalForm.values.applicantname}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched?.applicantname &&
                personalForm?.errors?.applicantname}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Passport Size Photo
              <span className="text-red-500">*</span>
            </label>
            <MobileFileUpload
              innerText={"Upload Photo"}
              selectedFileBase64={(value) =>
                personalForm.setFieldValue("passportsizephoto", value)
              }
              accept={"image/*"}
            />
            {personalForm?.values?.passportsizephoto && (
              <p className="font-medium">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-400"
                />{" "}
                File Uploaded
              </p>
            )}
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.passportsizephoto &&
                personalForm?.errors?.passportsizephoto}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Name of the Father <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="fathername"
              value={personalForm.values.fathername}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.fathername &&
                personalForm?.errors?.fathername}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Father's Occupation <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="fatheroccupation"
              value={personalForm.values.fatheroccupation}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.fatheroccupation &&
                personalForm?.errors?.fatheroccupation}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Father's Contact Number<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="fathercontact"
              value={personalForm.values.fathercontact}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.fathercontact &&
                personalForm?.errors?.fathercontact}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Name of the Mother <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="mothername"
              value={personalForm.values.mothername}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.mothername &&
                personalForm?.errors?.mothername}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Mother's Contact Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="mothercontact"
              value={personalForm.values.mothercontact}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.mothercontact &&
                personalForm?.errors?.mothercontact}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Family group photo <span className="text-red-500">*</span>
            </label>
            <MobileFileUpload
              innerText={"Upload Photo"}
              selectedFileBase64={(value) =>
                personalForm.setFieldValue("groupphoto", value)
              }
              accept={"image/*"}
            />
            {personalForm?.values?.groupphoto && (
              <p className="font-medium">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-600"
                />{" "}
                File Uploaded
              </p>
            )}
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched?.groupphoto &&
                personalForm?.errors?.groupphoto}
            </p>
          </div>
          <div className="flex flex-col my-2">
            <label className="font-medium">
              Address <span className="text-red-500">*</span>
            </label>
            <textarea
              rows={3}
              className="border border-gray-300 bg-gray-200"
              name="address"
              value={personalForm.values.address}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.address && personalForm?.errors?.address}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              District <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="district"
              value={personalForm.values.district}
              onChange={personalForm.handleChange}
            />

            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.district && personalForm?.errors?.district}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              State <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="state"
              value={personalForm.values.state}
              onChange={personalForm.handleChange}
            />

            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.state && personalForm?.errors?.state}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">Taluk</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="taluk"
              value={personalForm.values.taluk}
              onChange={personalForm.handleChange}
            />

            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.taluk && personalForm?.errors?.taluk}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Pincode <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="pincode"
              value={personalForm.values.pincode}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.pincode && personalForm?.errors?.pincode}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Date of Birth <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="dob"
              value={personalForm.values.dob}
              onChange={personalForm.handleChange}
              max={getTenYearsBeforeDate()}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.dob && personalForm?.errors?.dob}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Adhaar Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="adharnumber"
              value={personalForm.values.adharnumber}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.adharnumber &&
                personalForm?.errors?.adharnumber}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Gender <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-start items-center gap-x-3">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  className="h-4 w-4"
                  value="Male"
                  checked={personalForm.values.gender === "Male"}
                  onChange={personalForm.handleChange}
                />
                <label>Male</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  className="h-4 w-4"
                  value="Female"
                  checked={personalForm.values.gender === "Female"}
                  onChange={personalForm.handleChange}
                />
                <label>Female</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  className="h-4 w-4"
                  value="Others"
                  checked={personalForm.values.gender === "Others"}
                  onChange={personalForm.handleChange}
                />
                <label>Others</label>
              </div>
              <p className="text-red-500 font-medium text-md">
                {personalForm.touched.gender && personalForm?.errors?.gender}
              </p>
            </div>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Religion <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="religion"
              value={personalForm.values.religion}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.religion && personalForm?.errors?.religion}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Blood Group <span className="text-red-500">*</span>
            </label>
            <Select
              isSearchable={true}
              onChange={(value) =>
                personalForm.setFieldValue("bloodgroup", value.value)
              }
              options={BloodGroup}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.bloodgroup &&
                personalForm?.errors?.bloodgroup}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Marital Status <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-start items-center gap-x-3">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="maritalstatus"
                  className="h-4 w-4"
                  value="Married"
                  checked={personalForm.values.maritalstatus === "Married"}
                  onChange={personalForm.handleChange}
                />
                <label>Married</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="maritalstatus"
                  className="h-4 w-4"
                  value="Single"
                  checked={personalForm.values.maritalstatus === "Single"}
                  onChange={personalForm.handleChange}
                />
                <label>Single</label>
              </div>
            </div>
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.maritalstatus &&
                personalForm?.errors?.maritalstatus}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Nationality <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="nationality"
              value={personalForm.values.nationality}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.nationality &&
                personalForm?.errors?.nationality}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Mother Tongue <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="mothertongue"
              value={personalForm.values.mothertongue}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.mothertongue &&
                personalForm?.errors?.mothertongue}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Handicapped <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-start items-center gap-x-3">
              <div className="flex items-center">
                <input
                  type="radio"
                  name="handicapped"
                  className="h-4 w-4"
                  value="Yes"
                  checked={personalForm.values.handicapped === "Yes"}
                  onChange={personalForm.handleChange}
                />
                <label>Yes</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="handicapped"
                  className="h-4 w-4"
                  value="No"
                  checked={personalForm.values.handicapped === "No"}
                  onChange={personalForm.handleChange}
                />
                <label>No</label>
              </div>
              <p className="text-red-500 font-medium text-md">
                {personalForm.touched.handicapped &&
                  personalForm?.errors?.handicapped}
              </p>
            </div>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Mobile Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="mobile"
              value={personalForm.values.mobile}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.mobile && personalForm?.errors?.mobile}
            </p>
          </div>
          <div className="my-2">
            <label className="font-medium">
              Email ID <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded-sm bg-gray-200"
              name="email"
              value={personalForm.values.email}
              onChange={personalForm.handleChange}
            />
            <p className="text-red-500 font-medium text-md">
              {personalForm.touched.email && personalForm?.errors?.email}
            </p>
          </div>
        </div>
        <div className="text-right px-2 mb-2">
          <button className="bg-secondary px-5 text-white py-2 font-medium">
            Next <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto">
      <form onSubmit={personalForm.handleSubmit}>
        <MobileAccordion
          acordionTitle={"Personal Information"}
          accordionContent={InnerContent()}
          openAccordion={firstStepSubmitted && !secondStepSubmitted}
          accordionTitleClass={"bg-primary rounded-t-md text-white"}
          outerDivClass={"my-2"}
          disabled={!firstStepSubmitted && !secondStepSubmitted}
          savedAccordion={secondStepSubmitted}
          EnableEdit={() => {
            dispatch(setFirstFormStatus(true));
            dispatch(setSecondFormStatus(false));
            dispatch(setThirdFormStatus(true));
          }}
        />
      </form>
    </div>
  );
}

export default MobilePersonalInfoForm;
