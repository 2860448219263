import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";

function DesktopFileUpload({
  innerText,
  accept,
  selectedFileURL,
  preview,
  defaultPhoto,
}) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(defaultPhoto || null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setSelectedFile(fileReader.result);
        selectedFileURL(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }

    // Perform further actions with the selected file
  };
  return (
    <div>
      <button
        className="border flex gap-x-2 rounded-lg border-primary w-full py-2 px-2 whitespace-nowrap text-primary font-medium"
        onClick={handleButtonClick}
        type="button"
      >
        <svg
          width="23"
          height="23"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4405 9.40039C20.0405 9.71039 21.5105 11.5604 21.5105 15.6104V15.7404C21.5105 20.2104 19.7205 22.0004 15.2505 22.0004H8.74047C4.27047 22.0004 2.48047 20.2104 2.48047 15.7404V15.6104C2.48047 11.5904 3.93047 9.74039 7.47047 9.41039"
            stroke="#6457FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 15.5001V4.12012"
            stroke="#6457FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3504 6.35L12.0004 3L8.65039 6.35"
            stroke="#6457FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {innerText}
      </button>
      {preview && (
        <img src={selectedFile} className="h-[20vh] w-full mt-2" alt="" />
      )}
      <input
        type="file"
        ref={fileInputRef}
        accept={accept}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default DesktopFileUpload;
