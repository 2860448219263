const convertoformdata = (obj) => {
  const formData = new FormData();
  for (const key in obj) {
    if (key === "qualification")
      formData.append(key, JSON.stringify(obj[key]));
    else formData.append(key, obj[key]);
  }
  return formData;
};

export default convertoformdata;
