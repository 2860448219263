import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setFirstFormStatus,
  setSecondFormStatus,
  setThirdFormStatus,
} from "../../redux/formStatusStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FailureToast } from "../../components/mobile/toastify";
import createNewApplication from "../../hooks/createApplication";
import convertoformdata from "../../components/convertoformdata";
import getFileformat from "../../components/getfileformat";
function DesktopConfirmDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState([]);
  const CourseDetails = JSON.parse(localStorage.getItem("course-details-info"));
  const PersonalDetails = JSON.parse(
    localStorage.getItem("personal-details-info")
  );
  const HandleCourseEdit = () => {
    dispatch(setFirstFormStatus(false));
    localStorage.setItem("firstStepSubmitted", false);
    navigate("/course-details");
  };
  const HandlePersonalEdit = () => {
    dispatch(setSecondFormStatus(false));
    localStorage.setItem("secondStepSubmitted", false);
    navigate("/personal-details");
  };
  useEffect(() => {
    if (localStorage.getItem("thirdStepSubmitted") === "true") {
      dispatch(setFirstFormStatus(true));
      dispatch(setSecondFormStatus(true));
      dispatch(setThirdFormStatus(true));
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const CourseName = (id) => {
    const filterData = courseList.filter((items) => items.value === id);
    if (filterData.length > 0) return filterData[0]["label"];
  };

  const HandleSubmitForm = async () => {
    let courseData = JSON.parse(localStorage.getItem("course-details-info"));
    let personalData = JSON.parse(
      localStorage.getItem("personal-details-info")
    );
    let termsData = JSON.parse(localStorage.getItem("terms-conditions-info"));
    personalData["passportsizephoto"] = getFileformat(
      personalData["passportsizephoto"]
    );
    personalData["groupphoto"] = getFileformat(personalData["groupphoto"]);
    termsData["signature"] = getFileformat(termsData["signature"]);
    let result = {
      ...courseData,
      ...personalData,
      ...termsData,
    };
    const formattedresult = convertoformdata(result);
    const submitForm = await createNewApplication(formattedresult);
    console.log("submitForm", submitForm);
    if (submitForm) {
      localStorage.setItem("APPNO", submitForm);
      navigate("/thank-you");
    }
  };
  if (!PersonalDetails || !CourseDetails) return <></>;
  return (
    <div className=" ">
      <div>
        <div className="text-start text-white p-3 font-medium rounded-md bg-[#6457FF] mx-9">
          Course Details
          <FontAwesomeIcon
            onClick={() => HandleCourseEdit()}
            className="float-end cursor-pointer"
            icon={faPen}
          />
        </div>
        <div className="flex justify-evenly py-4">
          <div className="flex space-x-4">
            <p className="font-medium">Course</p>
            <p>{CourseName(CourseDetails?.course)}</p>
          </div>
          <div className="flex space-x-4">
            <p className="font-medium">Center</p>
            <p>{CourseDetails?.center.toUpperCase()}</p>
          </div>
        </div>
        {CourseDetails?.qualification?.length > 0 &&
          CourseDetails?.qualification?.map((item, index) => (
            <div className="mx-9 border p-4 space-y-4">
              <div className="flex space-x-10 bg-[#6457FF] p-2">
                <p className="text-white font-medium">
                  Qualification - {index + 1}
                </p>
                <div className="flex text-white  space-x-4">
                  <p className="font-medium text-gray-300">
                    {item.from.replaceAll("-", "/")}
                  </p>
                  <p className="font-medium text-gray-300">-</p>
                  <p className="font-medium text-gray-300">
                    {item.to.replaceAll("-", "/")}
                  </p>
                </div>
              </div>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div className="py-3">
                  <div className="flex space-x-4">
                    <p className="font-medium">Name of Examination Passed</p>
                    <p className="font-medium text-gray-500">{item.examname}</p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex space-x-4">
                    <p className="font-medium">Register Number</p>
                    <p className="font-medium text-gray-500">{item.regno}</p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex space-x-4">
                    <p className="font-medium">Marks</p>
                    <p className="font-medium text-gray-500">{item.marks}%</p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex space-x-4">
                    <p className="font-medium">Name of Board</p>
                    <p className="font-medium text-gray-500">
                      {item.nameofboard}
                    </p>
                  </div>
                </div>
                <div className="py-3">
                  <div className="flex space-x-4">
                    <p className="font-medium">School / College Name</p>
                    <p className="font-medium text-gray-500">{item.school}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className=" mt-4 text-start text-white p-3 font-medium rounded-md bg-[#6457FF] mx-9">
          Personal Details
          <FontAwesomeIcon
            className="float-end cursor-pointer"
            icon={faPen}
            onClick={() => HandlePersonalEdit()}
          />
        </div>
        <img
          src={PersonalDetails?.passportsizephoto}
          className="h-[15vh] m-7 border border-gray-500"
          alt=""
        />
        <div className="mx-9 mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Applicant Name</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.applicantname}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Date of Birth</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.dob}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Gender</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.gender}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Adhar Number</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.adharnumber}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Father Name</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.fathername}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Father Occupation</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.fatheroccupation}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Father Contact</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.fathercontact}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Mother Name</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mothername}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Mother Contact</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mothercontact}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Religion</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.religion}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Blood Group</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.bloodgroup}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Marital Status</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.maritalstatus}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Mobile</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mobile}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Nationality</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.nationality}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Mother Tongue</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.mothertongue}
              </p>
            </div>
          </div>
          <div className="py-3">
            <div className="flex space-x-4">
              <p className="font-medium">Email</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.email}
              </p>
            </div>
          </div>
          <div className="py-3 col-span-1 md:col-span-2 lg:col-span-4">
            <div className="flex space-x-4">
              <p className="font-medium">Full Address</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.address}, {PersonalDetails?.taluk},{" "}
                {PersonalDetails?.district} - {PersonalDetails?.pincode}
              </p>
            </div>
          </div>
          <div className="py-3 col-span-1 md:col-span-2 lg:col-span-4">
            <div className="flex space-x-4">
              <p className="font-medium">Are you Physically Handicapped</p>
              <p className="font-medium text-gray-500">
                {PersonalDetails?.handicapped}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 w-full">
        <div className="flex gap-x-4 items-center justify-end bg-white shadow-lg flex p-3 items-center justify-end">
          <button
            id="submit-btn"
            onClick={() => HandleSubmitForm()}
            className="bg-[#6457FF] text-white font-bold py-2 px-4 mr-4 flex items-center"
          >
            Proceed to Apply
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M14.4302 6.42999L20.5002 12.5L14.4302 18.57"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M3.5 12.5H20.33"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DesktopConfirmDetails;
