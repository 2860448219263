import axios from "axios";

const getApplicationList = async (value) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/admission_list.php`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("error on api call", err);
    });
};

export default getApplicationList;
