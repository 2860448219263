import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { usePDF } from "react-to-pdf";
import { FailureToast } from "../../components/mobile/toastify";
import AdmissionFormTemplate from "../../components/admission-form-template";

function MobileThankyouPage() {
  const logo = require("../../assets/images/png/logo.png");
  const [appno, setAppno] = useState(null);
  const [courseDetails, setCourseDetails] = useState({});
  const [personalDetails, setPersonalDetails?] = useState({});
  const [courseList, setCourseList] = useState([]);
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  useEffect(() => {
    const appno = localStorage.getItem("APPNO");
    setAppno(appno);
    localStorage.removeItem("firstStepSubmitted");
    localStorage.removeItem("secondStepSubmitted");
    localStorage.removeItem("thirdStepSubmitted");
    const course = localStorage.getItem("course-details-info");
    const personal = localStorage.getItem("personal-details-info");
    setCourseDetails(JSON.parse(course));
    setPersonalDetails(JSON.parse(personal));

    // get course list
    axios
      .get(`${process.env.REACT_APP_API_URL}get_course_list.php`)
      .then((res) => {
        setCourseList(res.data);
      })
      .catch((err) =>
        FailureToast("Something went wrong, Please try again later")
      );
  }, []);
  const CourseName = (id) => {
    const filterData = courseList.filter((items) => items.value === id);
    if (filterData.length > 0) return filterData[0]["label"];
  };
  return (
    <div>
      <div className="" ref={targetRef}>
        <AdmissionFormTemplate
          CourseName={CourseName}
          courseDetails={courseDetails}
          personalDetails={personalDetails}
          logo={logo}
          appno={appno}
        />
      </div>
      <div className="min-h-[90vh] flex flex-col items-center justify-center">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-green-700 text-[60px] my-3"
        />
        <p className="text-secondary text-center text-2xl font-medium italic">
          Thank you for choosing our institution to begin your journey toward
          excellence and success.
        </p>
        <p className="text-black text-xl mt-5">Your Application Code</p>
        <p className="text-black font-medium text-3xl mt-2">{appno}</p>
        <button
          className="px-5 py-2 text-white bg-red-500 border text-center"
          onClick={() => toPDF()}
        >
          Download PDF
        </button>
        <button className="bg-primary px-5 py-3 text-white mt-4 underline font-medium text-lg rounded-lg">
          Explore our website
        </button>
      </div>
    </div>
  );
}

export default MobileThankyouPage;
