import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import getApplicationList from "../../hooks/getApplicationList";

function DesktopAdmissionList() {
  const [listData, setListData] = useState([
    {
      applicant_name: "",
      application_number: "",
      applicant_contact_number: "",
      email: "",
    },
  ]);

  const columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.applicant_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Mobile",
      selector: (row) => row.applicant_contact_number,
    },
    {
      name: "Application No",
      selector: (row) => (
        <div>
          <p className="text-green-600 font-semibold">{row.application_number}</p>
        </div>
      ),
    },
    {
      name: "Course",
      selector: (row) => row.course_name,
    },
  ];
  const [filterText, setFilterText] = useState("");

  const filteredData = listData.filter(
    (item) =>
      item.applicant_name.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.email.toLowerCase().includes(filterText?.toLowerCase())
  );

  useEffect(() => {
    (async () => {
      const list = await getApplicationList();
      console.log("list", list);
      setListData(list);
    })();
  }, []);

  return (
    <div className="px-5">
      <h1 className="text-center text-2xl font-semibold my-5">
        Admission List
      </h1>
      <div className="flex justify-end">
        <input
          type="text"
          placeholder="Search..."
          className="border border-black"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{
            marginBottom: "10px",
            padding: "5px",
            width: "300px",
            fontSize: "16px",
          }}
        />
      </div>
      <div className="px-10 border border-gray-500">
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
        />
      </div>
    </div>
  );
}

export default DesktopAdmissionList;
