import axios from "axios";

const getApplicationById = async (value) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/get_application_info_by_id.php`, {
      id: value,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("error on api call", err);
    });
};

export default getApplicationById;
