const AdmissionFormTemplate = ({
  personalDetails,
  courseDetails,
  logo,
  CourseName,
  appno,
}) => {
  return (
    <div className="p-8 pt-3 max-w-3xl mx-auto border border-gray-300 rounded-lg shadow-md bg-gradient-to-r from-black-50 via-white to-black-50">
      <div className="flex justify-center mb-3 py-2 border">
        <div className="container flex justify-center gap-x-8 items-center">
          <img src={logo} alt="" className="h-[12vh]" />
          <div className="text-center">
            <p className="text-lg font-medium text-[#2B3690]">
              Tamilnadu Technical Training School and Paramedical Institute
            </p>
            <p className="font-medium text-md">Run by</p>
            <p className="font-medium text-md">
              Tamilnadu Santhome Educational & Charitable Trust
            </p>
          </div>
        </div>
      </div>
      <header className="text-center mb-8">
        <h1 className="text-3xl font-extrabold text-black-600 uppercase">
          Admission Form
        </h1>
        <p className="text-center text-black font-medium">
          Application no: {appno}
        </p>
      </header>

      <section className="mb-6">
        <h2 className="text-xl font-bold text-black-500 border-b-2 border-black-400 pb-2 mb-4">
          Personal Details
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <p>
              <span className="font-semibold text-black-700">
                Applicant Name:
              </span>{" "}
              {personalDetails?.applicantname}
            </p>
            <p>
              <span className="font-semibold text-black-700">Father Name:</span>{" "}
              {personalDetails?.fathername}
            </p>
            <p>
              <span className="font-semibold text-black-700">
                Father Occupation:
              </span>{" "}
              {personalDetails?.fatheroccupation}
            </p>
            <p>
              <span className="font-semibold text-black-700">
                Father Contact:
              </span>{" "}
              {personalDetails?.fathercontact}
            </p>
            <p>
              <span className="font-semibold text-black-700">Mother Name:</span>{" "}
              {personalDetails?.mothername}
            </p>
            <p>
              <span className="font-semibold text-black-700">
                Mother Contact:
              </span>{" "}
              {personalDetails?.mothercontact}
            </p>
          </div>
          <div>
            <p>
              <span className="font-semibold text-black-700">Address:</span>{" "}
              {personalDetails?.address}
            </p>
            <p>
              <span className="font-semibold text-black-700">District:</span>{" "}
              {personalDetails?.district}
            </p>
            <p>
              <span className="font-semibold text-black-700">State:</span>{" "}
              {personalDetails?.state}
            </p>
            <p>
              <span className="font-semibold text-black-700">Taluk:</span>{" "}
              {personalDetails?.taluk}
            </p>
            <p>
              <span className="font-semibold text-black-700">Pincode:</span>{" "}
              {personalDetails?.pincode}
            </p>
            <p>
              <span className="font-semibold text-black-700">
                Date of Birth:
              </span>{" "}
              {personalDetails?.dob}
            </p>
          </div>
          <div>
            <p>
              <span className="font-semibold text-black-700">
                Aadhar Number:
              </span>{" "}
              {personalDetails?.adharnumber}
            </p>
            <p>
              <span className="font-semibold text-black-700">Gender:</span>{" "}
              {personalDetails?.gender}
            </p>
            <p>
              <span className="font-semibold text-black-700">Religion:</span>{" "}
              {personalDetails?.religion}
            </p>
            <p>
              <span className="font-semibold text-black-700">Blood Group:</span>{" "}
              {personalDetails?.bloodgroup}
            </p>
            <p>
              <span className="font-semibold text-black-700">
                Marital Status:
              </span>{" "}
              {personalDetails?.maritalstatus}
            </p>
          </div>
          <div>
            <p>
              <span className="font-semibold text-black-700">Nationality:</span>{" "}
              {personalDetails?.nationality}
            </p>
            <p>
              <span className="font-semibold text-black-700">
                Mother Tongue:
              </span>{" "}
              {personalDetails?.mothertongue}
            </p>
            <p>
              <span className="font-semibold text-black-700">Handicapped:</span>{" "}
              {personalDetails?.handicapped}
            </p>
            <p>
              <span className="font-semibold text-black-700">Mobile:</span>{" "}
              {personalDetails?.mobile}
            </p>
            <p>
              <span className="font-semibold text-black-700">Email:</span>{" "}
              {personalDetails?.email}
            </p>
          </div>
        </div>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold text-black-500 border-b-2 border-black-400 pb-2 mb-4">
          Applicant Photo
        </h2>
        <div className="flex justify-center items-center mb-4">
          {personalDetails ? (
            <img
              src={`${process.env.REACT_APP_API_URL}applicant_photo/self/${appno}-self.jpg`}
              alt="ApplicantPhoto"
              className="w-40 h-40 object-cover border border-gray-300 rounded-md shadow-md"
            />
          ) : (
            <p className="text-gray-500">No photo provided</p>
          )}
        </div>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-bold text-black-500 border-b-2 border-black-400 pb-2 mb-4">
          Course Details
        </h2>
        <div>
          <p>
            <span className="font-semibold text-black-700">Course:</span>{" "}
            {CourseName(courseDetails?.course)}
          </p>
          <p>
            <span className="font-semibold text-black-700">Center:</span>{" "}
            {courseDetails?.center?.toUpperCase()}
          </p>
        </div>

        <div className="mt-4">
          <h3 className="text-lg font-bold text-black-600 mb-2">
            Qualification
          </h3>
          <table className="w-full border-collapse border border-black-300">
            <thead>
              <tr className="bg-black-100">
                <th className="border border-black-300 px-3 py-2">Exam Name</th>
                <th className="border border-black-300 px-3 py-2">Reg. No</th>
                <th className="border border-black-300 px-3 py-2">Marks</th>
                <th className="border border-black-300 px-3 py-2">Board</th>
                <th className="border border-black-300 px-3 py-2">School</th>
                <th className="border border-black-300 px-3 py-2">From</th>
                <th className="border border-black-300 px-3 py-2">To</th>
              </tr>
            </thead>
            <tbody>
              {courseDetails?.qualification?.map((qual, index) => (
                <tr key={index} className="even:bg-black-50">
                  <td className="border border-black-300 px-3 py-2">
                    {qual.examname}
                  </td>
                  <td className="border border-black-300 px-3 py-2">
                    {qual.regno}
                  </td>
                  <td className="border border-black-300 px-3 py-2">
                    {qual.marks}
                  </td>
                  <td className="border border-black-300 px-3 py-2">
                    {qual.nameofboard}
                  </td>
                  <td className="border border-black-300 px-3 py-2">
                    {qual.school}
                  </td>
                  <td className="border border-black-300 px-3 py-2">
                    {qual.from}
                  </td>
                  <td className="border border-black-300 px-3 py-2">
                    {qual.to}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <footer className="text-center mt-8">
        <p className="text-sm text-black-600">
          This is a system-generated form.
        </p>
      </footer>
    </div>
  );
};

export default AdmissionFormTemplate;
