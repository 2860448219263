const getFileformat = (fileData) => {
  const byteCharacters = atob(fileData.split(",")[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  const blob = new Blob(byteArrays, { type: "image/jpeg" }); // adjust MIME type accordingly
  const file = new File([blob], "image.jpg"); // Create a file object
  return file;
};
export default getFileformat;
